<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addDevice"
                >添加虫害信息</el-button
              >
            </el-form-item>
            <el-form-item label="" style="margin-left: 1vw">
              <el-input
                v-model="searchName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button
          style="margin-left: 1vw;"
          type="success"
          size="small"
          @click="search"
          >搜索</el-button
        >
        <el-button
          class="upDate"
          type="success"
          size="small"
          icon="el-icon-refresh"
          @click="update"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh;"
    >
      <el-table-column
        prop="diseasesName"
        label="虫害名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="diseasesContent"
        label="防治措施"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="添加时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="success" size="small"
            >编辑</el-button
          >
          <el-button @click="del(scope.row)" type="success" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加   编辑-->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0; font-size: 2.22vh;"
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="10vw"
        >
          <el-form-item label="虫害名称" prop="diseasesName">
            <el-input
              v-model="addForm.diseasesName"
              placeholder="请输入虫害名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="防治建议" prop="diseasesContent">
            <el-input
              type="textarea"
              v-model="addForm.diseasesContent"
              placeholder="请输入防治建议"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button
            type="primary"
            @click="saveForm('addForm')"
            :disabled="disabled"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: "苹果",
          advice: "及时用药",
          time: "2022-01-22 10:00:00",
        },
      ],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      searchName: "",
      addForm: {},
      rules: {
        diseasesName: [
          {
            required: true,
            message: "虫类名称不可为空",
            trigger: ["blur", "change"],
          },
        ],
        diseasesContent: [
          {
            required: true,
            message: "防治建议不可为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      formTitle: "",
      messageBox: false,
      disabled: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //请求列表数据
    getList() {
      this.$get("/remoteSelfHelp/getControlMeasures", {
        page: this.page,
        size: this.size,
        type: "",
        where: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          // //console.log("获取列表", this.tableData);
        }
      });
    },
    // 新增
    addDevice() {
      this.formTitle = "添加害虫信息";
      this.messageBox = true;
      this.disabled = false;
      if (this.$refs["addForm"] !== undefined) {
        this.$nextTick(() => {
          this.addForm = this.addForm;
          this.$refs["addForm"].resetFields();
        });
      }
    },
    //编辑
    edit(item) {
      this.formTitle = "编辑";
      this.messageBox = true;
      this.disabled = false;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(item));
      });
    },
    // 确认提交
    saveForm(formName) {
      let url = "/remoteSelfHelp/addOrUpdateControlMeasures";
      let updata = this.addForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$get(url, updata).then((res) => {
            this.disabled = true;
            if (res.data.state == "success") {
              this.getList();
              this.$message.success("操作成功");
              this.messageBox = false;
            } else {
              this.$message.error("操作失败！请稍后重试");
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/remoteSelfHelp/deleteControlMeasures", {
            id: item.id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.page = 1;
              this.getList();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 搜索
    search() {
      this.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    // 重置
    update() {
      this.searchName = "";
      this.getList();
      //console.log("重置");
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

.title /deep/ .el-form-item__content,
.el-date-editor,
.el-range-editor,
.el-input__inner,
.el-date-editor--datetimerange {
  padding: 0px 10px !important;
}

.title .upDate {
  position: absolute !important;
  right: 1vw !important;
}

.textA /deep/ .el-input__inner {
  height: 10vh;
}
</style>
